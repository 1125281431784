.Header{
width: 99vw;
height: 30vh;

}
.Header-1{
    width: 99vw;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}
.Logo-1{
    width: 20%;
    height: 80%;
}
.Logo-2{
    width: 10%;
    height: 80%;
}
.Logo-3{
    width: 50%;
    height: 80%;
    }
    .Logo-1-img{ 
        width: 100%;
        height: 100%;
    }
    .Logo-2-img{ 
        width: 100%;
        height: 100%;
    }

    
       .company-name-1{  
          position: relative;
          bottom: 35px;
          color: rgb(0, 174, 239);
          font-family: sans-serif;
          font-weight: 750;
          text-align: end;
        font-size: 45px;
       }
       .company-name-2{  
        position: relative;
        bottom: 70px;
        font-family: sans-serif;
        font-weight: 200;
        text-align: end;
        font-size: 20px;
     }
     .Header-2{
        width: 99vw;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgb(238, 238, 238);;
        
       }
       .menu-bar{
           height: 20%;
           border: 2px solid grey;
           background:rgb(15, 110, 177); ;
           display: flex;
           align-items: center;
           justify-content:space-around ;
          
           
        }
        .search-inbox-1{
            width: 70%;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .search-inbox-2{
            width: 90%;
            height: 50%;
            margin-left: 10%;
            
           
        }
       
        .skipto{ 
            border: solid black 1px;
            padding: 2px;
            border-radius: 5px;
            font-weight: 600;
        }
        .A{ 
            border: 1px solid black;
            border-radius: 20px;
            padding: 2px;
            }
            .anchor{ 
             color: white;
             font-weight: 650;
             padding: 5px;
             font-family: sans-serif;
             font-size: 18px;
             text-decoration: none;
            }
            .anchor:hover{
                cursor: pointer;
                background: white;
                color:blue ;
                transition: 1s;
            } 
            .content{ 
                background: rgb(75, 73, 73);
                color: whitesmoke;
            }
            .space{  
                margin-right: 50px;
            }
            .active{
                background: white;
                color:blue ;
            }  
            .Hover:hover{
               cursor: pointer;
               background: gray;
               color: white;
            }