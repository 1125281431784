.footer-page{
    height: 80vh;
    width: 99vw;
   
    
}
.footer-1{
    width: 100%;
height: 80%;
border: 1px solid rgb(39, 39, 39);
background:  #444;
color: white;
font-family: sans-serif;
font-size: 1em;;
font-weight: 500;

}
.footer-2{
    width: 100%;
    height: 20%;
    background:#252424;
    display: flex;
    justify-content: space-between;
}
.lists{
    margin-top: 20px;
    display: flex;
    justify-content: space-around; 
}
.headings{
    margin-bottom: 15px;
}
.image-logo{
    margin-bottom: 5px;
}
hr{
    height: 300px;
    }
    .footer-text{  
        color: rgba(218, 218, 218, 0.959);
        margin-left: 50px;
        font-family: sans-serif;
        font-weight: 50;
        margin-top: 20px;
        font-size: 15px;
        
    }
    .Bel{
        font-weight: 700;
        margin-bottom: 10px;
    }
    .date{ 
        color: rgba(218, 218, 218, 0.959);
        font-family: sans-serif;
        font-size: 20px;
        margin-right: 10px;
    }
    .aaa{
    color: white;
    margin-left: 5px;
    font-family: sans-serif;
    }
    .aaa:hover{ 
        cursor: pointer;
        text-decoration: underline;
    }
    .headings:hover{ 
        cursor: pointer;
        text-decoration: underline;
    }
    .image-logo:hover{ 
        cursor: pointer;
    
    }

